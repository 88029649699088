import React from "react";

const Header = () => {
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <div className="mobile-nav" style={{ width: "100%" }}>
          {/* <div className="topbar">
          <div className="container">
            <div className="topbar-inner">
              <p className="mb-0">
                Gladiatorsverse offical Contract Address: &nbsp;
                <a
                  href="https://testnet.bscscan.com/address/0x02474Fed73A29f787C8C7709D6Cc351fE945c441"
                  target="_blank"
                  // style="text-decoration: none; font-size: 14px; color: rgb(227, 50, 66);"
                >
                  0x02474Fed73A29f787C8C7709D6Cc351fE945c441
                </a>
              </p>
              <ul className="list-unstyled mb-0">
                <li>Buy $GAVE Token</li>
                <li>$GAVE Chart Dextool</li>
              </ul>
            </div>
          </div>
        </div> */}
          <nav className="navbar navbar-resp align-items-center">
            {/* <i
          className="bi bi-list mobile-nav-toggle"
          onClick={this.handleToggleVisibility}
        ></i> */}
            <ul>
              <li>
                <a
                  className="nav-link scrollto active-scroll-spy"
                  data-to-scrollspy-id="hero"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  data-to-scrollspy-id="characters"
                  href="/gladiators"
                >
                  Gladiators
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  data-to-scrollspy-id="vr"
                  href="#vr"
                >
                  Gameplay
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  data-to-scrollspy-id="marketplace"
                  href="#marketplace"
                >
                  Marketplace
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  data-to-scrollspy-id="vrcontrol"
                  href="#vrcontrol"
                >
                  Metaverse
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  data-to-scrollspy-id="roadmap"
                  href="#roadmap"
                >
                  Roadmap
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <span className="logo me-auto">
          <img src="assets/img/glad/logo.webp" alt="" />
        </span>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a
                className="nav-link scrollto active-scroll-spy"
                data-to-scrollspy-id="hero"
                href="/"
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                data-to-scrollspy-id="characters"
                href="/gladiators"
              >
                Gladiators
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                data-to-scrollspy-id="vr"
                href="#vr"
              >
                Gameplay
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                data-to-scrollspy-id="marketplace"
                href="#marketplace"
              >
                Marketplace
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                data-to-scrollspy-id="vrcontrol"
                href="#vrcontrol"
              >
                Metaverse
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                data-to-scrollspy-id="roadmap"
                href="#roadmap"
              >
                Roadmap
              </a>
            </li>
          </ul>
        </nav>
        <span className="mobile-nav-toggle">
          <i className="fa fa-list"></i>
        </span>

        <a href="#about" className="scrollto connect-wallet">
          <img
            src="assets/img/glad/connect.png"
            alt="Connect Wallet"
            className="img-fluid"
          />
        </a>
      </div>
    </header>
  );
};

export default Header;
