import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
// import "swiper/swiper-bundle";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
import Footer from "./Footer";
import Header from "./Header";
import "bootstrap/dist/js/bootstrap.min.js";
import ScrollSpy from "react-ui-scrollspy";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import VideoPlayer from "react-video-js-player";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
import Lottie from "react-lottie";
import animationData from "../loader.json";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import LazyLoad from "react-lazyload";

export default class Home extends Component {
  constructor(props) {
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);
    super(props);
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.state = {
      visibility: false,
      thumbsSwiper: null,
      setThumbsSwiper: null,
      progress: 0,
    };
  }

  handleToggleVisibility() {
    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      };
    });
  }
  updateProgress(value) {
    this.setState({ progress: value });
  }
  componentDidMount() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const videoPlaceholder = entry.target;
          const videoSrc = videoPlaceholder.getAttribute("data-src");
          const videoIframe = document.createElement("iframe");
          videoIframe.src = videoSrc;
          videoIframe.title = "blockchain Video";
          videoIframe.allow = "fullscreen";
          videoIframe.frameBorder = "0";
          videoIframe.scrolling = "no";
          videoIframe.className = "wistia_embed";
          videoIframe.name = "wistia_embed";
          videoIframe.msallowfullscreen = "msallowfullscreen";
          videoIframe.width = "100%";
          videoIframe.height = "100%";
          videoPlaceholder.parentNode.replaceChild(
            videoIframe,
            videoPlaceholder
          );
          observer.unobserve(videoPlaceholder);
        }
      });
    }, options);

    const videoPlaceholder = document.querySelectorAll(".video-placeholder");
    videoPlaceholder.forEach((iframe) => {
      observer.observe(iframe);
    });
    // observer.observe(videoPlaceholder);
  }

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
                // path: "your-animation-file.json",
                // renderer: "svg",
                // initialSegment: [0, progress * 0.01],
              }}
            />
          </div>
        </div>

        <Header />
        <section id="hero" className="align-items-center">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <iframe
                src="https://fast.wistia.net/embed/iframe/ulxku8n0ex?seo=false&videoFoam=true"
                id="slider-video"
                title="Gladiators Verse"
                allow="fullscreen"
                // allow="autoplay; fullscreen"
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                msallowfullscreen="msallowfullscreen"
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>

          <div
            className="container slider-text"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <h1>
              An Experience <br /> For Everyone <br /> Like Never Before
            </h1>
            <h2>
              An Advance P2E with a combination of Idle RPG and AI features
              <br /> and Metaverse based game
            </h2>
            <a
              href="https://play.google.com/store/apps/details?id=com.constantgamez.gladiatorsverse"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit https://play.google.com/store/apps/details?id=com.constantgamez.gladiatorsverse"
              className="scrollto"
            >
              <img
                src="assets/img/glad/started-btn.png"
                className="img-fluid mt-3"
                alt="Get Started"
              />
            </a>
          </div>
          {/* <a className="mute">
            <i id="slider-sound" className="bi-volume-mute"></i>
          </a> */}
        </section>

        {/* <section id=" " className="d-flex align-items-center">
          <div className="video-container"> */}
        {/* <video
              id="my-video"
              className=" "
              autoPlay
              loop
              muted
              poster="assets/img/glad/slider-bg.webp"
              data-setup="{}"
            >
              <source
                src="https://d3l9kj5yo53m75.cloudfront.net/file/video/2022/08/07/1659875642877ieq7rcp5m7b4/1659875642877ieq7rcp5m7b4.m3u8"
                type="application/x-mpegURL"
              />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                >
                  supports HTML5 video
                </a>
              </p>
            </video> */}
        {/* </div>
          <a className="mute">
            <i id="slider-sound" className="bi-volume-mute"></i>
          </a>
        </section> */}

        <main id="main">
          <ScrollSpy>
            <LazyLoad once>
              <a target="_blank" href="/gladiators">
                <section id="story" className="services section-bg story-bg">
                  <div className="container-fluid p-0" data-aos="fade-up">
                    <div className="section-title storyimg">
                      <h2>
                        <img
                          src="assets/img/glad/titles/our-story.webp"
                          className="img-fluid"
                          alt="Our Story"
                        />
                      </h2>
                      {/* <p className="story-content">
                    Gladiators Verse is a Play2Earn, IdleRPG and AI-Powered
                    crypto game based on great gladiators. The ecosystem of
                    Gladiators Verse brings together play-to-earn, idle-rpg, ai
                    technology with Oculus, android gameplay with its own
                    Blockchain and, GAVE token, the NFT collection and
                    marketplace, and its own metaverse with lands, markets,
                    holdings.
                  </p> */}
                    </div>
                    <div className="animation-wrapper">
                      <div className="particle particle-1"></div>
                      <div className="particle particle-2"></div>
                      <div className="particle particle-3"></div>
                      <div className="particle particle-4"></div>
                    </div>
                    {/* <div className="video">
                  <LazyLoad once>
                    <video autoPlay muted loop id="myVideo" className="chVideo">
                      <source
                        src="assets/videos/ch.mp4"
                        type="video/mp4"
                      ></source>
                      Your browser does not support HTML5 video.
                    </video>
                  </LazyLoad>
                </div> */}
                  </div>
                </section>
              </a>
            </LazyLoad>

            {/* <section id="characters" className="services section-bg">
              <div className="section-title">
                <h2>
                  <img
                    src="assets/img/glad/titles/characters.webp"
                    className="img-fluid"
                    alt="Characters"
                  />
                </h2>
              </div>

              <div
                className="videos-slider-2 d-flex align-items-center justify-content-center"
                id="videos-slider-2"
              >
                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>CARPOPHORUS</h2>
                    <img
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video className=" " id="slide-0" autoPlay loop muted>
                      <source
                        src="assets/img/glad/characters/carpophorus.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>IRIS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=""
                      id="slide-1"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/iris.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>LIVIA</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-2"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/julia.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>TATSUO</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-3"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/sumurai.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>TITUS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-4"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/bugaku.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>AMAZON</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-5"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/amazon.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>ATTILIUS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-6"
                      preload="none"
                      autoPlay
                      loop
                      muted
                    >
                      <source
                        src="assets/img/glad/characters/marcus.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>AELIA</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <video
                    className=" "
                    id="slide-7"
                    preload="none"
                    autoPlay
                    loop
                    muted
                  >
                    <source
                      src="assets/img/glad/characters/aelia.mp4"
                      type="video/mp4"
                    />
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        supports HTML5 video
                      </a>
                    </p>
                  </video> 
                </div>
              </div>

              <div
                className="slider-nav-thumbnails d-flex align-items-center justify-content-center"
                id="slider-nav-thumbnails"
              >
                <div>
                  <img
                    src="assets/img/glad/characters/carpophorus-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/iris-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/julia-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/sumurai-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/bugaku-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/amazon-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/marcus-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/aelia-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </section> */}

            <section id="vr" className="services section-bg vr-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/vr.webp"
                      className="img-fluid"
                      alt="VR"
                    />
                  </h2>
                  <p className="vr-content">
                    Gladiatorsvere has developed the arena and ancient rome
                    market in Unreal Engine to experience the ancient gladiators
                    virutal world. With Oculus, you are empowered to connect to
                    the GLADIATORS Metaverse anytime, anywhere with any device
                    you are using. Once you get in, you can real-time interact
                    with your team and players. The goal is to build a community
                    where everyone can play regardless of their location and
                    background.
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-5 col-6 col-sm-6">
                    <h2 className="h2vr">
                      We Transport you to <br></br>new Worlds
                    </h2>
                    <a
                      href="assets/videos/oculus.mp4"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Play"
                      className="play-btn"
                    >
                      <img
                        src="assets/img/glad/play-game.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-7 col-6 col-sm-6 arena-bg">
                    <img
                      src="assets/img/glad/vr-portrait.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="marketplace" className="services section-bg market-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-7">
                    <div className="section-title">
                      <h2>
                        <img
                          src="assets/img/glad/titles/nft-market.webp"
                          className="img-fluid"
                          alt="Marketplace"
                        />
                      </h2>
                      <p className="market-content">
                        Gladiators Verse will have its own NFT marketplace where
                        players can trade their weapons, accessories and other
                        game items easily. A gaming model of Gladiators Verse is
                        built on blockchain due to which it makes it more
                        reliable game to play. Gladiators battle in the arena
                        for their freedom, glory and pride which makes it more
                        adventurous.
                      </p>
                      <span>
                        <img
                          src="assets/img/glad/nft-market-btn.png"
                          className="img-fluid market-btn"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 nft-bg">
                    {/* <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img
                            src="assets/img/glad/nft-marketplace1.webp"
                            alt="Julie"
                            className="nft-img"
                          />
                        </div>
                        <div className="flip-card-back">
                          <img
                            src="assets/img/glad/nft-marketplace2.webp"
                            alt="Marcus"
                            className="nft-img"
                          />
                        </div>
                      </div>
                    </div> */}
                    <model-viewer
                      className="model-viewer"
                      src="assets/3d/axe.glb"
                      alt="Model"
                      autoplay
                      camera-controls
                      camera-orbit="180deg 100deg 100m" // Adjust the third value to control zoom
                      animation-name="AnimationName"
                      animation-clip="AnimationClip"
                      animation-cycles="2"
                      loading="lazy"
                      // style={{
                      //   width: "100%",
                      //   height: "85%",
                      //   padding: "10% 0 0 0",
                      // }}
                    ></model-viewer>
                  </div>
                </div>
              </div>
            </section>

            <section id="arenas" className="services section-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/fighting-arenas.webp"
                      className="img-fluid"
                      alt="Arenas"
                    />
                  </h2>
                </div>

                <Swiper
                  speed={600}
                  loop={true}
                  // spaceBetween={5}
                  slidesPerView={1}
                  // onSwiper={(swiper) => console.log(swiper)}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".ch-button-prev",
                    nextEl: ".ch-button-next",
                  }}
                >
                  <SwiperSlide>
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 arena-text">
                        <h2>STONE ARENA</h2>
                        <p className="arena-content">
                          The Stone Arena is a classic arena, inspired by the
                          ancient Roman Colosseum. It features an oval-shaped
                          arena, surrounded by seating for spectators. The Stone
                          Arena is a versatile arena, suitable for a wide range
                          of different combat styles.
                        </p>
                      </div>
                      <div className="col-md-8">
                        <img
                          src="assets/img/glad/arenas/arena-1.webp"
                          className="img-fluid arena-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 arena-text">
                        <h2>ICE ARENA</h2>
                        <p className="arena-content">
                          The Ice Arena is a frozen wasteland, with treacherous
                          ice floes and harsh conditions that can prove deadly
                          for the unprepared. Gladiators who enter the Ice Arena
                          will need to be nimble and quick, as well as
                          well-equipped for the frigid conditions.
                        </p>
                      </div>
                      <div className="col-md-8">
                        <img
                          src="assets/img/glad/arenas/arena-2.webp"
                          className="img-fluid arena-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 arena-text">
                        <h2>FIRE ARENA</h2>
                        <p className="arena-content">
                          The Fire Arena is a blazing inferno, with walls of
                          fire that threaten to burn the gladiators alive. The
                          heat is intense, and the ground is littered with
                          molten lava and scorched earth.
                        </p>
                      </div>
                      <div className="col-md-8">
                        <img
                          src="assets/img/glad/arenas/arena-3.webp"
                          className="img-fluid arena-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <div className="ch-button-next"></div>
                  <div className="ch-button-prev"></div>
                </Swiper>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center align-items-center arenas-thumb">
                    <img
                      src="assets/img/glad/arenas/arena-icon1.webp"
                      className="img-fluid"
                      alt=""
                    />
                    <img
                      src="assets/img/glad/arenas/arena-icon2.webp"
                      className="img-fluid"
                      alt=""
                    />
                    <img
                      src="assets/img/glad/arenas/arena-icon3.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="vrcontrol" className="services section-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="video-container">
                  {/* <iframe
                    // style={{ width: "100%", height: "auto" }}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/SNC0KBXctp8?vq=hd720"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe> */}
                  <LiteYouTubeEmbed
                    id="SNC0KBXctp8"
                    title="Gladiatorsverse Metaverse"
                    poster="maxresdefault"
                  />
                  {/* <lite-youtube videoid="SNC0KBXctp8" params="controls=0"></lite-youtube> */}
                </div>
                {/* <div className="wistia_responsive_padding">
                  <div className="wistia_responsive_wrapper">
                    <iframe
                      src="https://fast.wistia.net/embed/iframe/9cali861kf?seo=false&videoFoam=true"
                      title="vr Video"
                      allow="autoplay; fullscreen"
                      frameBorder="0"
                      scrolling="no"
                      className="wistia_embed"
                      name="wistia_embed"
                      msallowfullscreen="msallowfullscreen"
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div> */}

                <div className="section-title vr-section">
                  <h2>
                    <img
                      src="assets/img/glad/titles/vr-control.webp"
                      className="img-fluid"
                      alt="VR Control"
                    />
                  </h2>
                </div>
              </div>
            </section>

            {/* <section id="whitepaper" className="services section-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/whitepaper.webp"
                      className="img-fluid"
                      alt="Whitepaper"
                    />
                  </h2>
                  <a
                    href="http://whitepaper.gladiatorsverse.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Whitepaper"
                  >
                    <video muted id="scrollVideo" className="scrollVideo">
                      <source
                        src="assets/videos/scroll.mp4"
                        type="video/mp4"
                      ></source>
                      Your browser does not support HTML5 video.
                    </video>
                  </a>
                </div>
              </div>
            </section> */}

            <section id="tokenomics" className="services section-bg">
              <div
                className="container-fluid"
                style={{ padding: "0" }}
                data-aos="fade-up"
              >
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/tokenomics.webp"
                      className="img-fluid"
                      alt="Tokenomics"
                    />
                  </h2>
                </div>
                <div className="row d-flex justify-content-center align-items-center logo-row">
                  <div className="col-md-4 col-sm-6 col-4">
                    <img
                      src="assets/img/glad/tokens/token1.webp"
                      className="img-fluid token"
                      alt=""
                    />
                    <div className="token-div">
                      <h3>Total Supply</h3>
                      <h4>1 Billion</h4>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-4">
                    <img
                      src="assets/img/glad/tokens/token2.webp"
                      className="img-fluid token"
                      alt=""
                    />
                    <div className="token-div">
                      <h3>Token Name</h3>
                      <h4>GAVE</h4>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-4">
                    <img
                      src="assets/img/glad/tokens/token3.webp"
                      className="img-fluid token"
                      alt=""
                    />
                    <div className="token-div">
                      <h3>Token Symbol</h3>
                      <h4>$GAVE</h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="mobile" className="services">
              <div className="container-fluid bc-cont" data-aos="fade-up">
                <div className="wistia_responsive_padding">
                  <div className="wistia_responsive_wrapper">
                    <div
                      id="video-placeholder"
                      className="video-placeholder"
                      data-src="https://fast.wistia.net/embed/iframe/p6yzuws76u?seo=false&videoFoam=true"
                    ></div>
                  </div>
                </div>

                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/mobile.webp"
                      className="img-fluid"
                      alt="Mobile Game"
                    />
                  </h2>
                </div>
                <div className="row d-flex justify-content-center align-items-center chain-content">
                  <div className="col-md-12 blockchain-text">
                    {/* <h2>Mobile Version</h2> */}
                    <p>
                      Our mobile version will provide an immersive gaming
                      experience. It will be accessible on smartphones and
                      tablets, optimized for touch-screen controls, and offer
                      stunning graphics. Cross-platform gameplay will allow
                      seamless switching between devices, ensuring players can
                      continue their progress and interact with the game
                      community anywhere.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="roadmap" className="services section-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/roadmap.webp"
                      className="img-fluid"
                      alt="Roadmap"
                    />
                  </h2>
                </div>

                <div id="sword" className="bounce3">
                  <img
                    src="assets/img/glad/soward1.webp"
                    className="img-fluid sword-img "
                    alt=""
                  />
                </div>
                <Swiper
                  speed={600}
                  loop={true}
                  // spaceBetween={5}
                  slidesPerView={1}
                  // onSwiper={(swiper) => console.log(swiper)}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".ch-button-prev",
                    nextEl: ".ch-button-next",
                  }}
                >
                  <SwiperSlide>
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 col-sm-4 col-4">
                        <img
                          src="assets/img/glad/roadmap/phase1.webp"
                          className="img-fluid roadimg road1"
                          alt=""
                        />
                        <img
                          src="assets/img/glad/roadmap/phase2.webp"
                          className="img-fluid roadimg road2"
                          alt=""
                        />
                      </div>
                      <div className="col-md-2 col-sm-2 col-2"></div>
                      <div className="col-md-4 col-sm-4 col-4">
                        <img
                          src="assets/img/glad/roadmap/phase3.webp"
                          className="img-fluid roadimg road3"
                          alt=""
                        />
                        <img
                          src="assets/img/glad/roadmap/phase4.webp"
                          className="img-fluid roadimg road4"
                          alt=""
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 col-sm-4 col-4">
                        <img
                          src="assets/img/glad/roadmap/phase1.webp"
                          className="img-fluid roadimg road1"
                          alt=""
                        />
                        <img
                          src="assets/img/glad/roadmap/phase2.webp"
                          className="img-fluid roadimg road2"
                          alt=""
                        />
                      </div>
                      <div className="col-md-2 col-sm-2 col-2"></div>
                      <div className="col-md-4 col-sm-4 col-4">
                        <img
                          src="assets/img/glad/roadmap/phase3.webp"
                          className="img-fluid roadimg road3"
                          alt=""
                        />
                        <img
                          src="assets/img/glad/roadmap/phase4.webp"
                          className="img-fluid roadimg road4"
                          alt=""
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <div className="ch-button-next"></div>
                  <div className="ch-button-prev"></div>
                </Swiper>
              </div>
            </section>

            {/* <section id="mobile" className="services mobile-bg">
              <div className="container" data-aos="fade-up">
                <div className="row d-flex justify-content-center align-items-center mob-center">
                  <div className="col-md-4 col-sm-4 col-4">
                    <h2>
                      Mobile Version <br />
                    </h2>
                    <p className="mobile-content">
                      Our mobile version will provide an immersive gaming
                      experience, with all features and modes of the desktop
                      version. It will be accessible on smartphones and tablets,
                      optimized for touch-screen controls, and offer stunning
                      graphics. Cross-platform gameplay will allow seamless
                      switching between devices, ensuring players can continue
                      their progress and interact with the game community
                      anywhere.
                    </p>
                  </div>
                  <div className="col-md-8 col-sm-8 col-8">
                    <img
                      src="assets/img/glad/mobile-ch.webp"
                      className="img-fluid mobile-img"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section> */}

            <section id="coin" className="services section-bg market-bg">
              <div className="container-fluid" data-aos="fade-up">
                <div className="wistia_responsive_padding">
                  <div className="wistia_responsive_wrapper">
                    <div
                      id="video-placeholder2"
                      className="video-placeholder"
                      data-src="https://fast.wistia.net/embed/iframe/tbyl1th8vb?seo=false&videoFoam=true"
                    ></div>

                    {/* <iframe
                      src="https://fast.wistia.net/embed/iframe/tbyl1th8vb?seo=false&videoFoam=true"
                      title="shield Video"
                      allow="autoplay; fullscreen"
                      frameBorder="0"
                      scrolling="no"
                      className="wistia_embed"
                      name="wistia_embed"
                      msallowfullscreen="msallowfullscreen"
                      width="100%"
                      height="100%"
                    ></iframe> */}
                  </div>
                </div>

                <div className="section-title coin-section">
                  <h2>
                    <img
                      src="assets/img/glad/titles/verse-coin.webp"
                      className="img-fluid"
                      alt="Coin"
                    />
                  </h2>
                  <div className="gladcoin">
                    <img
                      src="assets/img/glad/coin/coin.webp"
                      className="img-fluid"
                      alt="Coin"
                    />
                    <h2>GAVE</h2>
                    <p className="market-content">
                      GAVE is the official in-game token that will be used to
                      exchange, accumulate, sell and buy items directly related
                      to the game. The value of the token is guaranteed by the
                      value of in-game resources and constant user contributions
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-12 d-flex justify-content-center align-items-center p-0 "> */}
                {/* <div className="video"> */}
                {/* <video
                        muted
                        loop
                        autoPlay
                        id="myVideo"
                        className="chVideo coinVideo"
                      >
                        
                        <source
                          src="assets/videos/shield.mp4"
                          type="video/mp4"
                        ></source>
                        Your browser does not support HTML5 video.
                      </video> */}

                {/* </div> */}
                {/* </div>
                </div> */}
              </div>
            </section>

            <LazyLoad once>
              <section
                id="partners"
                className="services section-bg partners-bg"
              >
                <div className="container-fluid" data-aos="fade-up">
                  {/* <div className="section-title">
                    <h2>
                      <img
                        src="assets/img/glad/titles/partners.webp"
                        className="img-fluid"
                        alt="Partners"
                      />
                    </h2>
                  </div> */}
                  <div className="row d-flex justify-content-center align-items-center logo-row partner-row">
                    <div className="col-md-3 col-sm-6 col-6">
                      <a
                        href="https://play.google.com/store/apps/dev?id=7319739121970247904"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit play.google.com/store/apps/dev?id=7319739121970247904"
                      >
                        <img
                          src="assets/img/glad/partners/avenir.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <a
                        href="http://pssi.online/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit pssi.online"
                      >
                        <img
                          src="assets/img/glad/partners/psis.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                    {/* <div className="col-md-3 col-sm-6 col-6">
                      <a
                        href="http://optimumlogix.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit optimumlogix.com"
                      >
                        <img
                          src="assets/img/glad/partners/optimum.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div> */}
                    <div className="col-md-3 col-sm-6 col-6">
                      <a
                        href="https://play.google.com/store/apps/dev?id=8845468465635056082"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit play.google.com/store/apps/dev?id=8845468465635056082"
                      >
                        <img
                          src="assets/img/glad/partners/papa.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <a
                        href="https://sparq.network"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit https://sparq.network"
                      >
                        <img
                          src="assets/img/glad/partners/sparq.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  {/* <div className="row d-flex  logo-row mt-3">
                    
                  </div> */}
                </div>
              </section>
            </LazyLoad>

            <section id="chain" className="services section-bg">
              <div className="container-fluid bc-cont" data-aos="fade-up">
                <div className="wistia_responsive_padding">
                  <div className="wistia_responsive_wrapper">
                    <div
                      id="video-placeholder"
                      className="video-placeholder"
                      data-src="https://fast.wistia.net/embed/iframe/zqqwqslnkz?seo=false&videoFoam=true"
                    ></div>
                    {/* <LazyLoad once>
                      <iframe
                        src="https://fast.wistia.net/embed/iframe/zqqwqslnkz?seo=false&videoFoam=true"
                        title="blockchain Video"
                        allow="fullscreen"
                        frameBorder="0"
                        scrolling="no"
                        className="wistia_embed"
                        name="wistia_embed"
                        msallowfullscreen="msallowfullscreen"
                        width="100%"
                        height="100%"
                      ></iframe>
                    </LazyLoad> */}
                  </div>
                </div>

                {/* <video
                  data-setup='{"controls": false, "autoplay": true, "preload": "auto", "muted": true, "loop": true}'
                  className="blockchainVideo"
                >
                  <source
                    src="https://d3l9kj5yo53m75.cloudfront.net/file/video/2022/08/07/1659872861081ledg2nn5vmj1/1659872861081ledg2nn5vmj1.m3u8"
                    type="application/x-mpegURL"
                  ></source>
                  Your browser does not support HTML5 video.
                </video> */}
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/crypto-chain.webp"
                      className="img-fluid"
                      alt="Chain"
                    />
                  </h2>
                </div>
                <div className="row d-flex justify-content-center align-items-center chain-content">
                  <div className="col-md-12 blockchain-text">
                    <h2>
                      Gladiator’s own <br /> In-game blockchain
                    </h2>
                    <p>
                      Gladiator game will be launched on it’s own <br />{" "}
                      blockchain.A gaming blockchain built specifically <br />{" "}
                      for gaming rewards and leader board systems.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* <LazyLoad once>
              <section id="tech" className="services section-bg tech-bg">
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>
                      <img
                        src="assets/img/glad/titles/technology.webp"
                        className="img-fluid"
                        alt="Technology"
                      />
                    </h2>
                  </div>
                  <div className="row d-flex justify-content-center align-items-center logo-row">
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/unity.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/binance.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/android.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/aws.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/oculus.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <img
                        src="assets/img/glad/tech/unreal.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </section>
            </LazyLoad> */}

            <LazyLoad once>
              <section
                id="audit"
                className="services section-bg tech-bg"
                style={{ padding: "60px 0 60px 0" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>
                      <img
                        src="assets/img/glad/titles/audit.webp"
                        className="img-fluid"
                        alt="Audit"
                      />
                    </h2>
                  </div>
                  <div className="row d-flex justify-content-center align-items-center logo-row">
                    <div className="col-md-4 col-sm-6 col-6">
                      <a
                        href="https://wp.hacken.io/wp-content/uploads/2023/08/Gladiators-Verse_SC-Audit-Report_08082023_SA-1684.pdf"
                        target="_blank"
                      >
                        <img
                          src="assets/img/glad/hacken.webp"
                          className="img-fluid audit-img"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </LazyLoad>

            {/* <section id="media" className="services section-bg partners-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/media.webp"
                      className="img-fluid"
                      alt="Media"
                    />
                  </h2>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-12"></div>
                </div>
              </div>
            </section> */}

            <section id="team" className="services section-bg team-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/team.webp"
                      className="img-fluid"
                      alt="Team"
                    />
                  </h2>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/waleed.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>WALEED WAHEED</h3>
                      <h4>CTO</h4>
                      <a
                        href="https://www.linkedin.com/in/waleed-waheed/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/waleed-waheed/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/awais.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>AWAIS MAQSOOD</h3>
                      <h4>CEO</h4>
                      <a
                        href="https://www.linkedin.com/in/awaismaqsood/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/awaismaqsood/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/hussain.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>HUSSAIN AHMED</h3>
                      <h4>BLOCKCHAIN DEV</h4>
                      <a
                        href="https://www.linkedin.com/in/hussain-ahmad/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/hussain-ahmad/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/bilawal.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>MIAN M BILAWAL</h3>
                      <h4>SQA</h4>
                      <a
                        href="https://www.linkedin.com/in/mian-muhammad-bilawal-18a00272/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/mian-muhammad-bilawal-18a00272/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/hamad.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>HAMAD KHAN</h3>
                      <h4>VR DEVELOPER</h4>
                      <a
                        href="https://www.linkedin.com/in/hamad-khan-4095a988/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/hamad-khan-4095a988/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/mariya.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>MARIYA RAJPUT</h3>
                      <h4>DIRECTOR COMMUNICATIONS</h4>
                      <a
                        href="https://www.linkedin.com/in/mariya-rajput-62170a16b/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/mariya-rajput-62170a16b/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/yasir.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>YASIR ALI</h3>
                      <h4>NETWORK ENGINEER</h4>
                      <a
                        href="https://www.linkedin.com/in/yasir-ali-4303479b/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/yasir-ali-4303479b/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/kainaat.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>KAINAAT ASAD</h3>
                      <h4>TECHNICAL WRITER</h4>
                      <a
                        href="https://www.linkedin.com/in/kainaat-asad-b81b74129/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/in/kainaat-asad-b81b74129/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="member">
                      <img
                        src="assets/img/glad/team/isra.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>ISRA QURESHI</h3>
                      <h4>SKETCH ARTIST</h4>
                      <a
                        href="https://www.linkedin.com/mwlite/in/isra-qureshi-b47b42177"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit www.linkedin.com/mwlite/in/isra-qureshi-b47b42177"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="faqs" className="services section-bg faq-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>
                    <img
                      src="assets/img/glad/titles/faqs.webp"
                      className="img-fluid"
                      alt="FAQ"
                    />
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center align-items-center">
                    <ul className="faq-list accordion" data-aos="fade-up">
                      <li className="cfaq1">
                        <div className="faq-collapsed" data-faq-id="faq1">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq1"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq1 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            What is Gladiator Metaverse? <hr></hr>
                          </span>
                        </div>
                        <div id="faq1" className="collapse">
                          <p>
                            Gladiator Metaverse is a blockchain-based
                            Play-to-Earn and idle RPG gaming project that allows
                            players to earn real-world value while enjoying
                            their gaming experience.
                          </p>
                        </div>
                      </li>

                      <li className="cfaq3">
                        <div className="faq-collapsed" data-faq-id="faq3">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq3"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq3 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            How can I earn tokens in Gladiator Metaverse?{" "}
                            <hr></hr>
                          </span>
                        </div>
                        <div id="faq3" className="collapse">
                          <p>
                            You can earn tokens in Gladiator Metaverse by
                            playing the game, participating in tournaments, and
                            completing quests. You can also earn tokens by
                            staking and providing liquidity. For more details
                            please read our whitepaper.
                          </p>
                        </div>
                      </li>

                      <li className="cfaq5">
                        <div className="faq-collapsed" data-faq-id="faq5">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq5"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq5 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            What is an Idle RPG and how it works in
                            Gladiatorsverse ?<hr></hr>
                          </span>
                        </div>
                        <div id="faq5" className="collapse">
                          <p>
                            An idle RPG is a type of role-playing game that
                            allows players to progress through the game without
                            actively playing it. In Gladiatorsverse, the idle
                            RPG feature allows players to earn rewards and
                            progress through the game even when they're not
                            actively playing it. This is achieved through a
                            system where the player's gladiators continue to
                            train and fight even when the player is offline. The
                            AI-powered gameplay ensures that the gladiators make
                            strategic decisions based on their abilities and the
                            opponent's weaknesses.
                          </p>
                        </div>
                      </li>

                      <li className="cfaq8">
                        <div className="faq-collapsed" data-faq-id="faq8">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq8"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq8 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            How can I become a KOL for Gladiatorsverse?{" "}
                            <hr></hr>
                          </span>
                        </div>
                        <div id="faq8" className="collapse">
                          <p>
                            If you are interested in becoming a KOL for
                            Gladiatorsverse, please contact us via email or
                            social media. We will evaluate your profile and
                            reach out to you if we feel you would be a good fit
                            for our project.
                          </p>
                        </div>
                      </li>

                      <li className="cfaq9">
                        <div className="faq-collapsed" data-faq-id="faq9">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq9"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq9 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            How can I contact Gladiatorsverse for support?{" "}
                            <hr></hr>
                          </span>
                        </div>
                        <div id="faq9" className="collapse">
                          <p>
                            You can contact our support team via email, social
                            media, or our website's contact form. We will do our
                            best to respond to your inquiry as quickly as
                            possible.
                          </p>
                        </div>
                      </li>

                      <li className="cfaq10">
                        <div className="faq-collapsed" data-faq-id="faq10">
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="img-fluid icon-show-faq3"
                            alt=""
                          />
                          <img
                            src="assets/img/glad/faq-btn.png"
                            className="icon-close-faq3 icon-hide"
                            alt=""
                          />{" "}
                          &nbsp;
                          <span>
                            How can I participate in the IDO launch? <hr></hr>
                          </span>
                        </div>
                        <div id="faq10" className="collapse">
                          <p>
                            The details of our IDO launch will be announced on
                            our website and social media channels. Please stay
                            tuned for updates and instructions on how to
                            participate.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section id="subscribe" className="services section-bg">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <img
                      src="assets/img/glad/julia.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe.webp"
                          className="img-fluid sub-join"
                          alt="subscribe"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/email-field.png"
                          className="img-fluid sub-email"
                          alt="email"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe-btn.png"
                          className="img-fluid sub-btn"
                          alt="subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollSpy>
        </main>

        <Footer />
      </React.Fragment>
    );
  }
}
