import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import $ from "jquery";
// import "swiper/css";
import "swiper/swiper.min.css";
// import "swiper/swiper-bundle";
import Footer from "./Footer";
import Header from "./Header";
import "bootstrap/dist/js/bootstrap.min.js";
import ScrollSpy from "react-ui-scrollspy";
import "reactjs-popup/dist/index.css";
import Lottie from "react-lottie";
import animationData from "../loader.json";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export default class Home extends Component {
  constructor(props) {
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);
    super(props);
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.state = {
      visibility: false,
      thumbsSwiper: null,
      setThumbsSwiper: null,
    };
  }
  handleToggleVisibility() {
    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      };
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </div>
        </div>
        
        <Header />
 
        <main id="main" style={{"padding": "6% 0 0 0"}}>
          <ScrollSpy>
            <section className="section-bg privacy-bg">
              <div className="container-fluid p-0" data-aos="fade-up">
                <div className="section-title ">
                  <h2 style={{ color: "#603813" }}>Privacy Policy</h2>
                  <p
                    style={{
                      textAlign: "left",
                      padding: "0 18% 5% 18%",
                      color: "#603813",
                    }}
                  >
                    At Gladiatorsverse, we are committed to protecting the
                    privacy and security of our users. This privacy policy
                    outlines the types of personal information we collect, how
                    we use it, and how we protect it. We collect personal
                    information from users when they create an account or engage
                    with our platform. This information includes: <br></br>
                    Name and username<br></br>
                    Email address<br></br>
                    IP address<br></br>
                    Browser and device information<br></br>
                    Payment information for in-game purchases<br></br>
                    How We Use Personal Information <br></br> We use personal
                    information for the following purposes:<br></br>
                    To create and manage user accounts<br></br>
                    To personalize user experiences on our platform<br></br>
                    To process payments for in-game purchases<br></br>
                    To communicate with users about our platform and services
                    <br></br>
                    To improve our platform and services<br></br>
                    How We Protect Personal Information We take reasonable
                    measures to protect personal information from unauthorized
                    access, use, and disclosure. These measures include:
                    <br></br>
                    Data encryption<br></br>
                    Secure data storage<br></br>
                    Regular system updates and maintenance<br></br>
                    Limited access to personal information by authorized
                    personnel only<br></br>
                    Third-Party Disclosure We do not sell, trade, or otherwise
                    transfer personal information to third parties without
                    users' consent. We may disclose personal information to
                    trusted third parties who assist us in operating our
                    platform or providing services to users, but only to the
                    extent necessary to carry out these services.<br></br>
                    Updates to Our Privacy Policy We may update our privacy
                    policy from time to time to reflect changes in our platform
                    or services. We encourage users to review our privacy policy
                    regularly to stay informed about how we collect, use, and
                    protect personal information.<br></br>
                    Contact Us If you have any questions or concerns about our
                    privacy policy or our use of personal information, please
                    contact us at <b>info@gladiatorsverse.io</b>
                    <br></br>
                  </p>
                </div>
              </div>
            </section>

            <section id="subscribe" className="services section-bg">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <img
                      src="assets/img/glad/julia.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe.webp"
                          className="img-fluid sub-join"
                          alt="subscribe"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/email-field.png"
                          className="img-fluid sub-email"
                          alt="email"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe-btn.png"
                          className="img-fluid sub-btn"
                          alt="subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollSpy>
        </main>

        <Footer />
         
      </React.Fragment>
    );
  }
}
