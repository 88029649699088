import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import Footer from "./Footer";
import Header from "./Header";
import "bootstrap/dist/js/bootstrap.min.js";
import ScrollSpy from "react-ui-scrollspy";
import "reactjs-popup/dist/index.css";
import Lottie from "react-lottie";
import animationData from "../loader.json";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export default class Gladiators extends Component {
  constructor(props) {
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);
    super(props);
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.state = {
      visibility: false,
      thumbsSwiper: null,
      setThumbsSwiper: null,
      progress: 0,
    };
  }

  handleToggleVisibility() {
    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      };
    });
  }
  updateProgress(value) {
    this.setState({ progress: value });
  }
  componentDidMount() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const videoPlaceholder = entry.target;
          const videoSrc = videoPlaceholder.getAttribute("data-src");
          const videoIframe = document.createElement("iframe");
          videoIframe.src = videoSrc;
          videoIframe.title = "blockchain Video";
          videoIframe.allow = "fullscreen";
          videoIframe.frameBorder = "0";
          videoIframe.scrolling = "no";
          videoIframe.className = "wistia_embed";
          videoIframe.name = "wistia_embed";
          videoIframe.msallowfullscreen = "msallowfullscreen";
          videoIframe.width = "100%";
          videoIframe.height = "100%";
          videoPlaceholder.parentNode.replaceChild(
            videoIframe,
            videoPlaceholder
          );
          observer.unobserve(videoPlaceholder);
        }
      });
    }, options);

    const videoPlaceholder = document.querySelectorAll(".video-placeholder");
    videoPlaceholder.forEach((iframe) => {
      observer.observe(iframe);
    });
    // observer.observe(videoPlaceholder);
  }

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
                // path: "your-animation-file.json",
                // renderer: "svg",
                // initialSegment: [0, progress * 0.01],
              }}
            />
          </div>
        </div>
        
        <Header />

        <main
          id="main"
          // style={{
          //   background:
          //     "linear-gradient(110.3deg, rgb(179 136 94 / 18%) 8.7%, rgb(78 26 14 / 84%) 47.5%, rgb(40 6 11 / 27%) 89.1%)",
          // }}
        >
          <ScrollSpy>
            <section id="gladiators" className="gladiators section-bg" style={{padding:"100px 0 0px 0"}}>
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h1 className="h1-gladiator">GLADIATORS</h1>
                </div>

                <hr></hr>

                <div className="row d-flex justify-content-center align-items-center" style={{"marginTop":"40px"}}>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=titus">
                        <img
                          src="assets/img/glad/gladiators/1.webp"
                          data-original-src="assets/img/glad/gladiators/1a.webp"
                          data-src="assets/img/glad/gladiators/1.webp"
                          className="image-container img-fluid"
                          alt="Gladiator1"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">TITUS</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=amazon">
                        <img
                          src="assets/img/glad/gladiators/2.webp"
                          data-original-src="assets/img/glad/gladiators/2a.webp"
                          data-src="assets/img/glad/gladiators/2.webp"
                          className="image-container img-fluid"
                          alt="Gladiator2"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">AMAZON</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=attilius">
                        <img
                          src="assets/img/glad/gladiators/3.webp"
                          data-original-src="assets/img/glad/gladiators/3a.webp"
                          data-src="assets/img/glad/gladiators/3.webp"
                          className="image-container img-fluid"
                          alt="Gladiator3"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">ATTILIUS</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row d-flex justify-content-center align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=aelia">
                        <img
                          src="assets/img/glad/gladiators/4.webp"
                          data-original-src="assets/img/glad/gladiators/4a.webp"
                          data-src="assets/img/glad/gladiators/4.webp"
                          className="image-container img-fluid"
                          alt="Gladiator1"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">AELIA</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=carpophorus">
                        <img
                          src="assets/img/glad/gladiators/5.webp"
                          data-original-src="assets/img/glad/gladiators/5a.webp"
                          data-src="assets/img/glad/gladiators/5.webp"
                          className="image-container img-fluid"
                          alt="Gladiator2"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">CARPOPHORUS</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=iris">
                        <img
                          src="assets/img/glad/gladiators/6.webp"
                          data-original-src="assets/img/glad/gladiators/6a.webp"
                          data-src="assets/img/glad/gladiators/6.webp"
                          className="image-container img-fluid"
                          alt="Gladiator3"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">IRIS</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=octavia">
                        <img
                          src="assets/img/glad/gladiators/8.webp"
                          data-original-src="assets/img/glad/gladiators/8a.webp"
                          data-src="assets/img/glad/gladiators/8.webp"
                          className="image-container img-fluid"
                          alt="Gladiator1"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">OCTAVIA</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=crixus">
                        <img
                          src="assets/img/glad/gladiators/9.webp"
                          data-original-src="assets/img/glad/gladiators/9a.webp"
                          data-src="assets/img/glad/gladiators/9.webp"
                          className="image-container img-fluid"
                          alt="Gladiator2"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">CRIXUS</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=livia">
                        <img
                          src="assets/img/glad/gladiators/10.webp"
                          data-original-src="assets/img/glad/gladiators/10a.webp"
                          data-src="assets/img/glad/gladiators/10.webp"
                          className="image-container img-fluid"
                          alt="Gladiator3"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">LIVIA</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-4">
                    <div className="gladiators">
                      <a href="gladiator?gladId=tatsuo">
                        <img
                          src="assets/img/glad/gladiators/7.webp"
                          data-original-src="assets/img/glad/gladiators/7a.webp"
                          data-src="assets/img/glad/gladiators/7.webp"
                          className="image-container img-fluid"
                          alt="Gladiator3"
                        />
                      </a>
                      <div className="image-overlay">
                        <h2 className="h2-gladiator">TATSUO</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <hr></hr>

            {/* <section id="characters" className="services section-bg">
              <div className="section-title">
                <h2>
                  <img
                    src="assets/img/glad/titles/characters.webp"
                    className="img-fluid"
                    alt="Characters"
                  />
                </h2>
              </div>

              <div
                className="videos-slider-2 d-flex align-items-center justify-content-center"
                id="videos-slider-2"
              >
                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>CARPOPHORUS</h2>
                    <img
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video className=" " id="slide-0" autoPlay loop muted>
                      <source
                        src="assets/img/glad/characters/carpophorus.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>IRIS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=""
                      id="slide-1"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/iris.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>LIVIA</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-2"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/julia.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>TATSUO</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-3"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/sumurai.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>TITUS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-4"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/bugaku.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>AMAZON</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-5"
                      preload="none"
                      loop
                      muted
                      autoPlay
                    >
                      <source
                        src="assets/img/glad/characters/amazon.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>ATTILIUS</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <LazyLoad once debounce={500}>
                    <video
                      className=" "
                      id="slide-6"
                      preload="none"
                      autoPlay
                      loop
                      muted
                    >
                      <source
                        src="assets/img/glad/characters/marcus.mp4"
                        type="video/mp4"
                      />
                      <p className="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  </LazyLoad>
                </div>

                <div className="bs-overlay">
                  <div className="content-platform-slick">
                    <h2>AELIA</h2>
                    <img
                      loading="lazy"
                      src="assets/img/glad/marketplace-btn.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        loading="lazy"
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <video
                    className=" "
                    id="slide-7"
                    preload="none"
                    autoPlay
                    loop
                    muted
                  >
                    <source
                      src="assets/img/glad/characters/aelia.mp4"
                      type="video/mp4"
                    />
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        supports HTML5 video
                      </a>
                    </p>
                  </video> 
                </div>
              </div>

              <div
                className="slider-nav-thumbnails d-flex align-items-center justify-content-center"
                id="slider-nav-thumbnails"
              >
                <div>
                  <img
                    src="assets/img/glad/characters/carpophorus-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/iris-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/julia-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/sumurai-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/bugaku-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/amazon-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/marcus-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/glad/characters/aelia-thumb.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </section> */}

            <section id="subscribe" className="services section-bg">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <img
                      src="assets/img/glad/julia.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe.webp"
                          className="img-fluid sub-join"
                          alt="subscribe"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/email-field.png"
                          className="img-fluid sub-email"
                          alt="email"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe-btn.png"
                          className="img-fluid sub-btn"
                          alt="subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollSpy>
        </main>

       

        <Footer />
      </React.Fragment>
    );
  }
}
