// import logo from './logo.svg';
import React from "react";
import "./App.css";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Gladiators from "./pages/Gladiators";
import Gladiator from "./pages/Gladiator";
// import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/gladiators" element={<Gladiators/>} />
          <Route path="/gladiator" element={<Gladiator />} />
          {/* <Route component={NotFound} /> */}
        </Routes>
      </div>
    </Router>
    //   <div className="App">
    //   {/* <ErrorBoundary> */}
    //     <Home />
    //   {/* </ErrorBoundary> */}
    // </div>
  );
}

export default App;
