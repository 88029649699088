import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center align-items-center p-5 mt2">
              <a
                href="https://twitter.com/GladiatorsVerse"
                className="con-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Twitter"
                />
                <div className="centered">
                  <i className="fab fa-twitter"></i>
                </div>
              </a>
              <a
                href="https://www.facebook.com/gladiatorsverse"
                className="con-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Instagram"
                />
                <div className="centered">
                  <i className="fab fa-facebook"></i>
                </div>
              </a>
              <a
                href="https://www.instagram.com/gladiatorsverse/"
                target="_blank"
                className="con-center"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Instagram"
                />
                <div className="centered">
                  <i className="fab fa-instagram"></i>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/gladiatorsverse/mycompany/"
                className="con-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Instagram"
                />
                <div className="centered">
                  <i className="fab fa-linkedin"></i>
                </div>
              </a>
              <a href="https://t.me/gladiatorsverse" className="con-center">
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Telegram"
                />
                <div className="centered">
                  <i className="fab fa-telegram-plane"></i>
                </div>
              </a>
              <a
                href="https://discord.com/channels/964119616282587149/964119700218970134"
                target="_blank"
                rel="noopener noreferrer"
                className="con-center"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Discord"
                />
                <div className="centered">
                  <i className="fab fa-discord"></i>
                </div>
              </a>
              <a
                href="https://medium.com/@gladiatorsverse"
                target="_blank"
                rel="noopener noreferrer"
                className="con-center"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Medium"
                />
                <div className="centered">
                  <i className="fab fa-medium"></i>
                </div>
              </a>
              <a href="https://vm.tiktok.com/ZSRLCjbRW/" className="con-center">
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Tiktok"
                />
                <div className="centered">
                  <i className="fab fa-tiktok"></i>
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCzwNKQUQj7SYzi7_jbXdPjA"
                className="con-center"
              >
                <img
                  src="assets/img/glad/socialicon.png"
                  className="img-fluid"
                  alt="Youtube"
                />
                <div className="centered">
                  <i className="fab fa-youtube"></i>
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 footer-contact">
              <h3>
                <img
                  src="assets/img/glad/logo.webp"
                  alt=""
                  style={{ width: "50%" }}
                />
              </h3>
              <p>
                Gladiators Verse is a Play2Earn and idle RPG AI-Powered crypto
                game based on great gladiators. The ecosystem of Gladiators
                Verse brings together play-to-earn and idle RPG technology with
                Oculus, android gameplay with its own Blockchain and, GAVE
                token, the NFT collection and marketplace, and its own metaverse
                with lands, markets, holdings.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 footer-contact">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <a href="#hero">Home</a>
                </li>
                <li>
                  <a href="#story">Story</a>
                </li>
                <li>
                  <a href="#characters">Characters</a>
                </li>
                <li>
                  <a href="#vr">Gameplay</a>
                </li>
                <li>
                  <a href="#mobile">Mobile Version</a>
                </li>
                <li>
                  <a href="#arenas">Arenas</a>
                </li>
                <li>
                  <a href="#vrcontrol">VR</a>
                </li>
                <li>
                  <a href="#tech">Technologies</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-contact">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a
                    href="http://whitepaper.gladiatorsverse.io/index"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>
                <li>
                  <a
                    href="https://drive.google.com/file/d/13YHqNaKhRY3UGIat8pX7GnMC7WWP1oyJ/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pitch Deck
                  </a>
                </li>
                <li>
                  <a href="#marketplace">Marketplace</a>
                </li>
                <li>
                  <a href="#tokenomics">Tokenomics</a>
                </li>
                <li>
                  <a href="#roadmap">Roadmap</a>
                </li>
                <li>
                  <a href="#coin">Coin</a>
                </li>
                <li>
                  <a href="#chain">Blockchain</a>
                </li>
                <li>
                  <a href="#partners">Partners</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/glad/footer-line.webp"
        className="footer-line"
        alt=""
      />
      <div className="container py-4">
        <div className="text-center">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Gladiators Verse</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </div>
      <a
        id="scrolltop"
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
        aria-label="Scroll to Top"
      >
        <i className="fas fa-angle-up"></i>
      </a>
    </footer>
  );
};

export default Footer;
