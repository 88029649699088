import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import { useParams } from "react-router-dom";
// import $ from "jquery";

import Footer from "./Footer";
import Header from "./Header";
import "bootstrap/dist/js/bootstrap.min.js";
import ScrollSpy from "react-ui-scrollspy";
import "reactjs-popup/dist/index.css";
import Lottie from "react-lottie";
import animationData from "../loader.json";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import LazyLoad from "react-lazyload";

export default class Gladiator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeTab: "tab1", // Set the initial active tab
      selectedImage: null,
      selectedImageIndex: 0,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleTabChange(tabId) {
    this.setState({ activeTab: tabId });
  }

  handleToggleVisibility() {
    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      };
    });
  }

  handleThumbnailClick = (image, index) => {
    this.setState({
      selectedImage: image,
      selectedImageIndex: index,
    });
  };

  handleNextClick = () => {
    const { selectedImageIndex } = this.state;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const gladId = urlSearchParams.get("gladId");
    const images = [
      `assets/img/glad/gladiators/detail/${gladId}-detail1.webp`,
      `assets/img/glad/gladiators/detail/${gladId}-detail2.webp`,
      `assets/img/glad/gladiators/detail/${gladId}-detail3.webp`,
    ];
    const nextIndex = (selectedImageIndex + 1) % images.length;
    this.setState({
      selectedImage: images[nextIndex],
      selectedImageIndex: nextIndex,
    });
  };

  handlePreviousClick = () => {
    const { selectedImageIndex } = this.state;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const gladId = urlSearchParams.get("gladId");
    const images = [
      `assets/img/glad/gladiators/detail/${gladId}-detail1.webp`,
      `assets/img/glad/gladiators/detail/${gladId}-detail2.webp`,
      `assets/img/glad/gladiators/detail/${gladId}-detail3.webp`,
    ];
    const previousIndex =
      (selectedImageIndex - 1 + images.length) % images.length;
    this.setState({
      selectedImage: images[previousIndex],
      selectedImageIndex: previousIndex,
    });
  };

  handleCloseModal = () => {
    this.setState({
      selectedImage: null,
      selectedImageIndex: 0,
    });
  };

  componentDidMount() {
    //   const urlSearchParams = new URLSearchParams(window.location.search);
    //   const gladId = urlSearchParams.get("gladId");

    //   let bgImage = "";
    //   let bgMobile = "";

    //   if (gladId === "titus") {
    //     bgImage = "titus.webp";
    //     bgMobile = "titus-mobile.webp";
    //   } else if (gladId === "amazon") {
    //     bgImage = "amazon.webp";
    //     bgMobile = "amazon-mobile.webp";
    //   } else if (gladId === "attilius") {
    //     bgImage = "attilius.webp";
    //     bgMobile = "attiliusmobile.webp";
    //   } else if (gladId === "aelia") {
    //     bgImage = "aelia.webp";
    //     bgMobile = "aelia-mobile.webp";
    //   } else if (gladId === "carpophorus") {
    //     bgImage = "carpophorus.webp";
    //     bgMobile = "carpophorus-mobile.webp";
    //   } else if (gladId === "crixus") {
    //     bgImage = "crixus.webp";
    //     bgMobile = "crixus-mobile.webp";
    //   } else if (gladId === "livia") {
    //     bgImage = "livia.webp";
    //     bgMobile = "livia-mobile.webp";
    //   } else if (gladId === "octavia") {
    //     bgImage = "octavia.webp";
    //     bgMobile = "octavia-mobile.webp";
    //   } else if (gladId === "tatsuo") {
    //     bgImage = "tatsuo.webp";
    //     bgMobile = "tatsuo-mobile.webp";
    //   } else if (gladId === "iris") {
    //     bgImage = "iris.webp";
    //     bgMobile = "iris-mobile.webp";
    //   }

    //   this.setState({
    //     bgImage
    //   });
    //   this.setState({
    //     bgMobile
    //   });

    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize(); // Call it initially to set the correct background image
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed
    const urlSearchParams = new URLSearchParams(window.location.search);
    const gladId = urlSearchParams.get("gladId");
    let bgImage = "";
    let bgMobile = "";

    if (gladId === "titus") {
      bgImage = "titus.webp";
      bgMobile = "titus-mobile.webp";
    } else if (gladId === "amazon") {
      bgImage = "amazon.webp";
      bgMobile = "amazon-mobile.webp";
    } else if (gladId === "attilius") {
      bgImage = "attilius.webp";
      bgMobile = "attiliusmobile.webp";
    } else if (gladId === "aelia") {
      bgImage = "aelia.webp";
      bgMobile = "aelia-mobile.webp";
    } else if (gladId === "carpophorus") {
      bgImage = "carpophorus.webp";
      bgMobile = "carpophorus-mobile.webp";
    } else if (gladId === "crixus") {
      bgImage = "crixus.webp";
      bgMobile = "crixus-mobile.webp";
    } else if (gladId === "livia") {
      bgImage = "livia.webp";
      bgMobile = "livia-mobile.webp";
    } else if (gladId === "octavia") {
      bgImage = "octavia.webp";
      bgMobile = "octavia-mobile.webp";
    } else if (gladId === "tatsuo") {
      bgImage = "tatsuo.webp";
      bgMobile = "tatsuo-mobile.webp";
    } else if (gladId === "iris") {
      bgImage = "iris.webp";
      bgMobile = "iris-mobile.webp";
    }

    const newBgImage = isMobile ? bgMobile : bgImage;

    // alert(bgMobile);

    if (this.state.bgImage !== newBgImage) {
      this.setState({ bgImage: newBgImage });
    }
  };

  render() {
    const { bgImage } = this.state;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const gladId = urlSearchParams.get("gladId");
    const { activeTab } = this.state;
    let title = "";
    let description = "";
    let videoLink = "";
    // let bgImage = "";
    let chlink = "";
    const { selectedImage } = this.state;

    const images = [
      "assets/img/glad/gladiators/thumbs/" + gladId + "-thumb1.webp",
      "assets/img/glad/gladiators/thumbs/" + gladId + "-thumb2.webp",
      "assets/img/glad/gladiators/thumbs/" + gladId + "-thumb3.webp",
    ];

    const detailimages = [
      "assets/img/glad/gladiators/detail/" + gladId + "-detail1.webp",
      "assets/img/glad/gladiators/detail/" + gladId + "-detail2.webp",
      "assets/img/glad/gladiators/detail/" + gladId + "-detail3.webp",
    ];

    // Update title and description based on gladid value
    if (gladId === "titus") {
      title = "Titus";
      chlink = "https://whitepaper.gladiatorsverse.io/index#titus";
      videoLink = "titus.mp4";
      description =
        "In the vast forests of the Roman Empire lived a man named Titus, who was abandoned in the wilderness at a young age and raised by wolves. He learned to hunt and fight for survival, developing strength and agility beyond that of a typical human. As he grew older, he was discovered by a group of gladiator trainers who recognized his natural abilities and brought him to Rome to become a gladiator.....";
    } else if (gladId === "amazon") {
      title = "Amazon";
      chlink = "https://whitepaper.gladiatorsverse.io/index#amazon";
      videoLink = "amazon.mp4";
      description =
        "The Amazons were a legendary tribe of fierce female warriors from ancient Greek mythology. They were believed to live in the region of Scythia, which corresponds to modern-day Ukraine and Russia. The Amazon women were said to be skilled archers and horse riders, and they were known for their bravery and combat prowess.....";
    } else if (gladId === "attilius") {
      title = "Attilius";
      chlink = "https://whitepaper.gladiatorsverse.io/index#attilius";
      videoLink = "attilius.mp4";
      description =
        "Attilius was born into a family of moderate means, but he had a passion for glory that he could not find in the mundane existence of his daily life. He had heard of the great arena and the feats of the gladiators that fought there. He knew that it was a brutal life, but he also knew that it was a way to make a name for himself, to rise above his current station.....";
    } else if (gladId === "aelia") {
      title = "Aelia";
      chlink = "https://whitepaper.gladiatorsverse.io/index#aelia";
      videoLink = "aelia.mp4";
      description =
        "Aelia was born into a family of Roman nobility and was brought up to be a skilled fighter. From a young age, she was trained in the art of combat by her father, who recognized her natural talent for it. Her father was a renowned gladiator and trained her in secret, knowing that women were not permitted to fight in the gladiatorial games. Aelia's mother, however, disapproved of her training and believed that her daughter should focus on finding a suitable husband instead.....";
    } else if (gladId === "carpophorus") {
      title = "Carpophorus";
      chlink = "https://whitepaper.gladiatorsverse.io/index#carpophorus";
      videoLink = "carpophorus.mp4";
      description =
        "Carpophorus was born into a family of gladiators and from a young age, he was trained to fight in the arena. As he grew older, he became a skilled bestiarius, specializing in fighting wild animals. His reputation grew quickly, as he defeated bears, lions, buffalo, panthers, and even a leopard, earning him the nickname 'Leopard Killer'.....";
    } else if (gladId === "crixus") {
      title = "Crixus";
      chlink = "https://whitepaper.gladiatorsverse.io/index#crixus";
      videoLink = "crixus.mp4";
      description =
        "As a young boy, Crixus was taken from his family and sold into slavery in Capua. There, he was trained as a gladiator, learning to fight with a variety of weapons and become a skilled warrior in the arena. Crixus became one of the most successful and popular gladiators in the training school of Lentulus Batiatus, known for his strength, speed, and fearless fighting style. But he was also restless, yearning for freedom and a life beyond the blood-soaked sand of the arena.....";
    } else if (gladId === "livia") {
      title = "Livia";
      chlink = "https://whitepaper.gladiatorsverse.io/index#livia";
      videoLink = "livia.mp4";
      description =
        "Livia was born in ancient Rome to a noble family, but she never fit in with their expectations. She was always more interested in studying the ancient texts about the gods and the universe, and she longed to explore beyond the boundaries of her own time. One day, she stumbled upon a mysterious portal that transported her to a distant future where she discovered new technologies and powers that she could bring back to her own time.....";
    } else if (gladId === "octavia") {
      title = "Octavia";
      chlink = "https://whitepaper.gladiatorsverse.io/index#octavia";
      videoLink = "octavia.mp4";
      description =
        "Octavia was born to a family of renowned alchemists in ancient Rome, and from a young age, she showed a keen interest in the art of transmutation. Her parents, being a bit unorthodox, believed in the principle of holistic education and wanted their daughter to learn about the world beyond the laboratory. As a result, Octavia was trained in sword fighting and combat, in addition to her academic pursuits.....";
    } else if (gladId === "tatsuo") {
      title = "Tatsuo";
      chlink = "https://whitepaper.gladiatorsverse.io/index#tatsuo";
      videoLink = "tatsuo.mp4";
      description =
        "Tatsuo, a skilled warrior from feudal Japan who became a gladiator in the Roman arena. Tatsuo was born into a noble samurai family in the late 16th century in Japan. As a young man, he trained in the art of bushido, honing his skills with the katana sword and other weapons.....";
    } else if (gladId === "iris") {
      title = "Iris";
      chlink = "https://whitepaper.gladiatorsverse.io/index#iris";
      videoLink = "iris.mp4";
      description =
        "As a young girl, iris always had a fascination with gladiators. She would sneak into the arena to watch them fight and dreamt of one day becoming one herself. When she was old enough, she ran away from home and joined a gladiator training school, disguising herself as a boy to avoid detection. There, she trained relentlessly and learned how to fight with a knife sword, becoming one of the most skilled gladiators in the school.....";
    }

    console.log(gladId);
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
                // path: "your-animation-file.json",
                // renderer: "svg",
                // initialSegment: [0, progress * 0.01],
              }}
            />
          </div>
        </div>

        <Header />

        <section
          className="align-items-center bg-glad"
          style={{
            backgroundImage:
              'url("assets/img/glad/gladiators/' + bgImage + '")',
          }}
        >
          <div
            className="container-fluid slider-text-glad glad-top"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <div style={{ marginLeft: "5%" }}>
              <ul className="nav nav-tabs glad nav-glad">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "tab1" ? "active" : ""
                    }`}
                    onClick={() => this.handleTabChange("tab1")}
                    href="#"
                  >
                    TOP
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "tab2" ? "active" : ""
                    }`}
                    onClick={() => this.handleTabChange("tab2")}
                    href="#"
                  >
                    COMMAND LIST
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "tab3" ? "active" : ""
                    }`}
                    onClick={() => this.handleTabChange("tab3")}
                    href="#"
                  >
                    COSTUME
                  </a>
                </li>
              </ul>
              <h1 className="glad-h1">{title}</h1>
            </div>

            <div className="tab-content">
              <div
                className={`tab-content-first tab-pane ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                id="tab1"
              >
                <p className="glad-description">
                  {description}
                  <a
                    href={chlink}
                    target="_blank"
                    style={{ color: "#eb7901", background: "#0000004a" }}
                  >
                    Read More
                  </a>
                </p>

                <div className="row">
                  <div className="col-md-4">
                    <img
                      src="assets/img/glad/gladiators/att1.webp"
                      alt="Image 1"
                      className="image"
                    />
                    <img
                      src="assets/img/glad/gladiators/att2.webp"
                      alt="Image 2"
                      className="image"
                    />
                    <img
                      src="assets/img/glad/gladiators/att3.webp"
                      alt="Image 3"
                      className="image"
                    />
                    <img
                      src="assets/img/glad/gladiators/att4.webp"
                      alt="Image 3"
                      className="image"
                    />
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <img
                      src="assets/img/glad/gladiators/att5.webp"
                      alt="Image 4"
                      className="image"
                    />
                    <img
                      src="assets/img/glad/gladiators/att6.webp"
                      alt="Image 5"
                      className="image"
                    />
                    <img
                      src="assets/img/glad/gladiators/att7.webp"
                      alt="Image 6"
                      className="image"
                    />
                  </div>
                </div>

                <div className="thumbnail-slider" style={{ marginTop: "20px" }}>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail"
                      onClick={() =>
                        this.handleThumbnailClick(detailimages[index], index)
                      }
                    />
                  ))}
                </div>
                {selectedImage && (
                  <div className="image-modal glad-modal">
                    <img
                      src={selectedImage}
                      alt="Selected Image"
                      style={{ width: "80%", height: "auto" }}
                    />
                    <span
                      className="close-button"
                      onClick={this.handleCloseModal}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                    <span
                      className="nav-button prev-button"
                      onClick={this.handlePreviousClick}
                      style={{ position: "absolute", left: "2%", top: "45%" }}
                    >
                      <i className="fas fa-angle-left"></i>
                    </span>
                    <span
                      className="nav-button next-button"
                      onClick={this.handleNextClick}
                      style={{ position: "absolute", right: "2%", top: "45%" }}
                    >
                      <i className="fas fa-angle-right"></i>
                    </span>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane ${activeTab === "tab2" ? "active" : ""}`}
                id="tab2"
              >
                {/* <p>Content of Tab 2</p> */}

                <div className="bs-overlay">
                  <div className="assets-slick">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="assets-slick-mobile">
                    <div className="p-2">
                      <img
                        src="assets/img/glad/asset1.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset2.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="assets/img/glad/asset3.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <LazyLoad once debounce={500}> */}
                  <video className=" " id="slide-0" autoPlay loop muted>
                    <source
                      src={`assets/img/glad/characters/${videoLink}`}
                      type="video/mp4"
                    />
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        supports HTML5 video
                      </a>
                    </p>
                  </video>
                  {/* </LazyLoad> */}
                </div>
              </div>

              <div
                className={`tab-pane ${activeTab === "tab3" ? "active" : ""}`}
                id="tab3"
              >
                <p>Content of Tab 3</p>
              </div>
            </div>
          </div>
        </section>

        {/* <main id="main">
          <ScrollSpy>
            <section id="subscribe" className="services section-bg">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <img
                      src="assets/img/glad/julia.webp"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe.webp"
                          className="img-fluid sub-join"
                          alt="subscribe"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/email-field.png"
                          className="img-fluid sub-email"
                          alt="email"
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center p-2">
                        <img
                          src="assets/img/glad/subscribe-btn.png"
                          className="img-fluid sub-btn"
                          alt="subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollSpy>
        </main>

        <Footer /> */}
      </React.Fragment>
    );
  }
}
